import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import LenderReducer from 'reducers/LenderReducer';
import OfficerReducer from 'reducers/OfficerReducer';
import LoginReducer from 'reducers/LoginReducer';
import ReviewReducer from 'reducers/ReviewReducer';
import ForgotPasswordReducer from 'reducers/ForgotPasswordReducer';

import { STORAGE_TOKEN_NAME, STORAGE_USER_TYPE } from 'Constants';
import { LOGOUT } from 'actions/action_types';
import ChangePasswordReducer from 'reducers/ChangePasswordReducer';

const appReducer = combineReducers({
  form: formReducer,
  LoginReducer: LoginReducer,
  LenderReducer: LenderReducer,
  OfficerReducer: OfficerReducer,
  ReviewReducer: ReviewReducer,
  ForgotPasswordReducer: ForgotPasswordReducer,
  ChangePasswordReducer: ChangePasswordReducer,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    state = undefined;
    localStorage.removeItem(STORAGE_TOKEN_NAME);
    localStorage.removeItem(STORAGE_USER_TYPE);
  }

  return appReducer(state, action);
};

export default rootReducer;
