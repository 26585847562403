import 'babel-polyfill';
import 'url-search-params-polyfill';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import ReduxPromise from 'redux-promise';
import thunk from 'redux-thunk';

import AppRouter from 'routes';

// Reducers
import reducers from 'reducers';

// Assets
import 'bootstrap/dist/css/bootstrap.css';
import 'assets/css/app.css';
import 'react-toastify/dist/ReactToastify.css';

// Global Settings
const createStoreWithMiddleware = applyMiddleware(ReduxPromise, thunk)(createStore);

function App() {
  return (
    <Provider store={createStoreWithMiddleware(reducers)}>
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
