import { toast } from 'react-toastify';

import { FORGOT_PASSWORD } from 'actions/action_types';

const initialValues = { login: false, fetching: false, userData: {}, token: '', isSuccess: false };

export default function ForgotPasswordReducer(state = initialValues, action) {
  switch (action.type) {
    case FORGOT_PASSWORD:
      if (
        (action.payload.response && action.payload.response.status === 200) ||
        (action.payload.status && action.payload.status === 200)
      ) {
        toast.success('Please check your email for further instructions', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return { ...state, isSuccess: true };
      } else if (
        (action.payload.response && action.payload.response.status === 401) ||
        (action.payload.status && action.payload.status === 401)
      ) {
        const errorMessage = action.payload.response
          ? action.payload.response.data.error
          : action.payload.data && action.payload.data.error
          ? action.payload.data.error
          : 'Something went wrong.';
        toast.error(errorMessage, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return { ...state, isSuccess: false };
      } else if (
        (action.payload.response && action.payload.response.status === 400) ||
        (action.payload.status && action.payload.status === 400)
      ) {
        const errorMessage2 = action.payload.response.data;
        toast.error(errorMessage2, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return { ...state, isSuccess: false };
      } else {
        return { ...state };
      }

    default:
      return { ...state };
  }
}
