import { toast } from 'react-toastify';

import { LOGIN, SHOW_LOGIN } from 'actions/action_types';

const initialValues = { login: false, fetching: false, userData: {}, token: '' };

export default function LoginReducer(state = initialValues, action) {
  switch (action.type) {
    case LOGIN:
      if (
        (action.payload.response && action.payload.response.status === 200) ||
        (action.payload.status && action.payload.status === 200)
      ) {
        toast.success('Login successful', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return {
          ...state,
          login: true,
          fetching: false,
          showError: false,
          userData: action.payload.data.user,
          token: action.payload.data.token,
        };
      } else if (
        (action.payload.response && action.payload.response.status === 401) ||
        (action.payload.status && action.payload.status === 401)
      ) {
        const errorMessage = action.payload.response
          ? action.payload.response.data.error
          : action.payload.data && action.payload.data.error
          ? action.payload.data.error
          : 'Something went wrong.';
        toast.error(errorMessage, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return { ...state, login: false };
      } else {
        return { ...state };
      }
    case SHOW_LOGIN:
      return { ...state, ...initialValues };
    default:
      return { ...state };
  }
}
