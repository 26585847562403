import React from 'react';
import { Navigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { STORAGE_TOKEN_NAME, STORAGE_USER_TYPE } from 'Constants';

export function AuthRoute({ children }) {
  const isAuthenticated = localStorage.getItem(STORAGE_TOKEN_NAME);
  return isAuthenticated ? children : <Navigate to='/login' replace />;
}

export function LoginRoute({ children }) {
  const token = localStorage.getItem(STORAGE_TOKEN_NAME);
  const officerId = token ? jwtDecode(token).officer : '';
  const isOfficer = parseInt(localStorage.getItem(STORAGE_USER_TYPE), 10) === 1;

  return !token ? (
    children
  ) : isOfficer ? (
    <Navigate to={`/officer/${officerId}`} replace />
  ) : (
    <Navigate to='/officers' replace />
  );
  // <Component login={() => false} {...props} />
}
