/**
 * Backend api URL
 * */
export const BASE_URL = `${process.env.REACT_APP_BACKEND_URL}/api/v1`;

/**
 * Main website front URL
 * */
export const FRONT_END_MAIN = `${process.env.REACT_APP_FRONTEND_MAIN}`;
/**
 * LinkedIn Constants
 */
export const LINKED_IN_REDIRECT_URL = `${process.env.REACT_APP_LINKEDIN_REDIRECT_URL}`;
export const LINKED_IN_CLIENT_ID = `${process.env.REACT_APP_LINKEDIN_CLIENT_ID}`;
/**
 * Facebook Constants
 */
export const FACEBOOK_APP_ID = `${process.env.REACT_APP_FACEBOOK_APP_ID}`;
/**
 * local storage token name
 * */
export const STORAGE_TOKEN_NAME = 'lendaid';
export const STORAGE_USER_TYPE = 'lendaid_type';
/**
 * Full names of social profiles platform types
 * */
export const SOCIAL_PROFILE_PLATFORM_TYPES = {
  FB: 'Facebook',
  GB: 'Google My Business',
  IG: 'Instagram',
  IN: 'LinkedIn',
  TW: 'Twitter',
  ZI: 'Zillow',
};
/**
 * Sentry DSN
 * */
export const SENTRY_DSN = `${process.env.SENTRY_DSN}`;
/**
 * Google Constants
 */
export const GOOGLE_APP_ID = `${process.env.REACT_APP_GOOGLE_APP_ID}`;
/**
 * User Types
 */
export const OFFICER_USER_TYPE = '1';
export const LENDER_USER_TYPE = '2';
