import React from 'react';
import loaderImg from 'assets/images/loader.svg';

function Loader() {
  return (
    <div className='loader-container'>
      <img className='loader-image' src={loaderImg} alt='Loader' />
    </div>
  );
}

export default Loader;
