import Loadable from 'react-loadable';
import Loading from 'components/Common/Loader';

/**
 * react loadable components
 * */
export const Login = Loadable({
  loader: () => import('../containers/LoginContainer'),
  loading: Loading,
});

export const ForgotPassword = Loadable({
  loader: () => import('../components/Pages/ForgotPassword'),
  loading: Loading,
});
export const ResetPassword = Loadable({
  loader: () => import('../components/Pages/ResetPassword'),
  loading: Loading,
});
export const Lender = Loadable({
  loader: () => import('../containers/LenderContainer'),
  loading: Loading,
});
export const EditOfficerForm = Loadable({
  loader: () => import('../containers/EditFormContainer'),
  loading: Loading,
});
export const ChangeOfficerPasswordForm = Loadable({
  loader: () => import('../containers/ChangeOfficerPasswordFormContainer'),
  loading: Loading,
});
export const NotFound = Loadable({
  loader: () => import('../components/Pages/404'),
  loading: Loading,
});
export const Reviews = Loadable({
  loader: () => import('../components/Pages/Reviews/index'),
  loading: Loading,
});
export const CreateOfficer = Loadable({
  loader: () => import('../components/Pages/CreateOfficer'),
  loading: Loading,
});
