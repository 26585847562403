import { toast } from 'react-toastify';

import { CHANGE_PASSWORD } from 'actions/action_types';

const initialValues = { fetching: false, logout: false, updated: false, avatar: null };

export default function ChangePasswordReducer(state = initialValues, action) {
  switch (action.type) {
    case CHANGE_PASSWORD:
      if (
        (action.payload.response && action.payload.response.status === 200) ||
        (action.payload.status && action.payload.status === 200)
      ) {
        toast.success('Password successfully changed', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        return { ...state, fetching: false, logout: false };
      } else if (
        (action.payload.response && action.payload.response.status === 401) ||
        (action.payload.status && action.payload.status === 401)
      ) {
        toast.error('You are not authenticated', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return { ...state, fetching: false, logout: true };
      } else if (
        (action.payload.response && action.payload.response.status === 400) ||
        (action.payload.status && action.payload.status === 400)
      ) {
        toast.error(action.payload.response.data.new_password[0], {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return { ...state, fetching: false, logout: false };
      } else if (action.payload.response && action.payload.response.status) {
        toast.error(action.payload.response.statusText, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return { ...state, fetching: false };
      } else {
        return { ...state, fetching: false };
      }

    default:
      return { ...state };
  }
}
