import { toast } from 'react-toastify';

import { FETCH_LENDER_OFFICERS } from 'actions/action_types';

const intialValues = { lender: {}, fetching: true, count: null, next: null, previous: null, redirect: false };

export default function LenderReducer(state = intialValues, action) {
  switch (action.type) {
    case FETCH_LENDER_OFFICERS:
      if (
        (action.payload.response && action.payload.response.status === 200) ||
        (action.payload.status && action.payload.status === 200)
      ) {
        return {
          ...state,
          lender: action.payload.data.results,
          fetching: false,
          count: action.payload.data.count,
          next: action.payload.data.next,
          previous: action.payload.data.previous,
          redirect: false,
        };
      } else if (
        (action.payload.response && action.payload.response.status === 401) ||
        (action.payload.status && action.payload.status === 401) ||
        (action.payload.response && action.payload.response.status === 403) ||
        (action.payload.status && action.payload.status === 403)
      ) {
        const errorMessage = action.payload.response
          ? action.payload.response.data.error
          : action.payload.data && action.payload.data.error
          ? action.payload.data.error
          : 'Something went wrong.';
        toast.error(errorMessage, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return { ...state, lender: {}, fetching: false, redirect: true };
      } else {
        return { ...state };
      }
    default:
      return { ...state };
  }
}
