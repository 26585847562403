import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Components
import { AuthRoute, LoginRoute } from 'components/Common/HigherOrderComponents/AuthComponent';
import {
  Login,
  Reviews,
  CreateOfficer,
  ChangeOfficerPasswordForm,
  EditOfficerForm,
  Lender,
  ForgotPassword,
  ResetPassword,
  NotFound,
} from 'utilities/ReactLoadableComponents';
import Logout from 'components/Pages/Logout';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';

function AppRouter() {
  return (
    <Routes>
      <Route
        path='/login'
        element={
          <LoginRoute>
            <Login />
          </LoginRoute>
        }
      />
      <Route
        exect
        path='/officer/:officerId/reviews'
        element={
          <AuthRoute>
            <Reviews />
          </AuthRoute>
        }
      />
      <Route
        exect
        path='/officers/officer'
        element={
          <AuthRoute>
            <CreateOfficer />
          </AuthRoute>
        }
      />
      <Route
        exect
        path='/officer/change_password'
        element={
          <AuthRoute>
            <ChangeOfficerPasswordForm />
          </AuthRoute>
        }
      />
      <Route
        exect
        path='/officer/:officerId'
        element={
          <AuthRoute>
            <EditOfficerForm />
          </AuthRoute>
        }
      />
      <Route
        path='/officers'
        element={
          <AuthRoute>
            <Lender />
          </AuthRoute>
        }
      />
      <Route
        path='/linkedin/'
        element={
          <AuthRoute>
            <LinkedInCallback />
          </AuthRoute>
        }
      />
      <Route exect path='/forgotpassword' element={<ForgotPassword />} />
      <Route exect path='/password_reset/:reset_token' element={<ResetPassword />} />
      <Route exect path='/404' element={<NotFound />} />
      <Route path='/logout' element={<Logout />} />
      <Route
        exect
        path='/'
        element={
          <LoginRoute>
            <Login />
          </LoginRoute>
        }
      />
      <Route exect path='*' element={<NotFound />} />
    </Routes>
  );
}

export default AppRouter;
