import React from 'react';
import ReactDOM from 'react-dom';
import { init as sentryInit } from '@sentry/browser';
import { SENTRY_DSN } from 'Constants';

// Components
import App from './App';

// sentry sdk
if (SENTRY_DSN !== 'undefined') {
  sentryInit({
    dsn: SENTRY_DSN,
  });
}

ReactDOM.render(<App />, document.getElementById('root'));
