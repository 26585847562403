import { toast } from 'react-toastify';

import {
  FETCHING,
  FETCH_OFFICER,
  IS_LINKEDIN_CONNECTED,
  CONNECT_LINKEDIN,
  CONNECTED_LINKEDIN,
  LINKEDIN_COULDNT_CONNECT,
  LINKEDIN_DISCONNECTED,
  FACEBOOK_DISCONNECTED,
  GOOGLE_DISCONNECTED,
  CONNECT_FACEBOOK,
  CONNECT_GOOGLE,
  IS_FACEBOOK_CONNECTED,
  IS_GOOGLE_CONNECTED,
  SAVE_OFFICER,
  CREATE_OFFICER,
  UPLOAD_AVATAR,
  FETCH_LOCATIONS,
} from 'actions/action_types';
import { LENDER_USER_TYPE, STORAGE_USER_TYPE } from '../Constants';

const initialValues = {
  officer: {},
  fetching: true,
  logout: false,
  updated: false,
  avatar: null,
  isLinkedInConnected: false,
  isLoadingLinkedIn: true,
  isFacebookConnected: false,
  isGoogleConnected: false,
  isLoadingFacebook: true,
  isLoadingGoogle: true,
};

export default function officerReducer(state = initialValues, action) {
  switch (action.type) {
    case FETCHING:
      return { ...state, fetching: true };
    case FETCH_OFFICER:
      if (
        (action.payload.response && action.payload.response.status === 200) ||
        (action.payload.status && action.payload.status === 200)
      )
        return { ...state, officer: action.payload.data, fetching: false, logout: false };
      else if (
        (action.payload.response && action.payload.response.status === 401) ||
        (action.payload.status && action.payload.status === 401)
      )
        return { ...state, officer: null, fetching: false, logout: true };
      else if (
        (action.payload.response && action.payload.response.status === 403) ||
        (action.payload.status && action.payload.status === 403) ||
        (action.payload.response && action.payload.response.status === 404) ||
        (action.payload.status && action.payload.status === 404)
      )
        return { ...state, officer: null, fetching: false, logout: false };
      else {
        return { ...state, fetching: false };
      }

    case IS_LINKEDIN_CONNECTED:
      if (action.payload.data && action.payload.status === 200)
        return { ...state, isLinkedInConnected: action.payload.data.is_token_valid, isLoadingLinkedIn: false };
      else return { ...state, isLoadingLinkedIn: false };
    case LINKEDIN_DISCONNECTED:
      if (action.payload.status === 200) return { ...state, isLinkedInConnected: false, isLoadingLinkedIn: false };
      else return { ...state, isLoadingLinkedIn: false };
    case CONNECT_LINKEDIN:
      return { ...state, isLoadingLinkedIn: true };
    case CONNECTED_LINKEDIN:
      toast.success('Successfully connected', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return { ...state, isLoadingLinkedIn: false, isLinkedInConnected: true };
    case LINKEDIN_COULDNT_CONNECT:
      toast.error("Couldn't connect", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return { ...state, isLinkedInConnected: false, isLoadingLinkedIn: false };

    case IS_FACEBOOK_CONNECTED:
      if (action.payload.data && action.payload.status === 200)
        return { ...state, isFacebookConnected: action.payload.data.is_token_valid, isLoadingFacebook: false };
      else return { ...state, isLoadingFacebook: false };
    case FACEBOOK_DISCONNECTED:
      if (action.payload.status === 200) return { ...state, isFacebookConnected: false, isLoadingFacebook: false };
      else return { ...state, isLoadingFacebook: false };
    case CONNECT_FACEBOOK:
      if (action.payload.status === 200) {
        toast.success('Successfully connected', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return { ...state, isFacebookConnected: true, isLoadingFacebook: false };
      } else {
        toast.error("Couldn't connect", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return { ...state, isLoadingFacebook: false, isFacebookConnected: false };
      }

    case IS_GOOGLE_CONNECTED:
      if (action.payload.data && action.payload.status === 200) {
        return { ...state, isGoogleConnected: action.payload.data.is_token_valid, isLoadingGoogle: false };
      } else return { ...state, isLoadingGoogle: false };
    case GOOGLE_DISCONNECTED:
      if (action.payload.status === 200) {
        state.officer.is_gmb_connected = false;
        state.locations = null;
        return { ...state, isGoogleConnected: false, isLoadingGoogle: false };
      } else return { ...state, isLoadingGoogle: false };
    case CONNECT_GOOGLE:
      if (action.payload.status === 200) {
        toast.success('Successfully connected', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        state.officer.is_gmb_connected = true;
        return { ...state, isGoogleConnected: true, isLoadingGoogle: false };
      } else {
        toast.error("Couldn't connect", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return { ...state, isLoadingGoogle: false, isGoogleConnected: false };
      }
    case FETCH_LOCATIONS:
      if (action.payload.status === 200) {
        return { ...state, locations: action.payload.data };
      } else {
        return { ...state, locations: null };
      }

    case SAVE_OFFICER:
      if (
        (action.payload.response && action.payload.response.status === 200) ||
        (action.payload.status && action.payload.status === 200)
      ) {
        if (localStorage.getItem(STORAGE_USER_TYPE) === LENDER_USER_TYPE) {
          state.isGoogleConnected = !action.payload.data.is_gmb_link_enabled && action.payload.data.is_gmb_connected;
        } else {
          state.isGoogleConnected = action.payload.data.is_gmb_link_enabled && action.payload.data.is_gmb_connected;
        }

        if (!state.isGoogleConnected) {
          state.locations = null;
        }
        toast.success('data successfully saved', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return { ...state, officer: action.payload.data, fetching: false };
      } else if (
        (action.payload.response && action.payload.response.status === 400) ||
        (action.payload.status && action.payload.status === 400)
      ) {
        toast.error('Something went wrong', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return { ...state, fetching: false };
      } else if (
        (action.payload.response && action.payload.response.status === 401) ||
        (action.payload.status && action.payload.status === 401)
      )
        return { ...state, officer: null, fetching: false, logout: true };
      else {
        return { ...state, fetching: false };
      }
    case CREATE_OFFICER:
      if (
        (action.payload.response && action.payload.response.status === 200) ||
        (action.payload.status && action.payload.status === 200)
      ) {
        toast.success('data successfully saved', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return { ...state, fetching: false };
      } else if (
        (action.payload.response && action.payload.response.status === 401) ||
        (action.payload.status && action.payload.status === 401) ||
        (action.payload.response && action.payload.response.status === 400) ||
        (action.payload.status && action.payload.status === 400)
      ) {
        toast.error(String(action.payload.response.data[Object.keys(action.payload.response.data)[0]]), {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return { ...state, officer: null, fetching: false, logout: true };
      } else {
        return { ...state, fetching: false };
      }
    case UPLOAD_AVATAR:
      if (
        (action.payload.response && action.payload.response.status === 200) ||
        (action.payload.status && action.payload.status === 200)
      ) {
        toast.success('Picture successfully uploaded.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return {
          ...state,
          fetching: false,
          officer: {
            ...state.officer,
            avatar: `${process.env.REACT_APP_BACKEND_URL}/media/${action.payload.data.avatar}` || '',
          },
        };
      } else if (
        (action.payload.response && action.payload.response.status === 401) ||
        (action.payload.status && action.payload.status === 401)
      ) {
        toast.error('You are not authenticated', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return { ...state, officer: null, fetching: false, logout: true };
      } else if (action.payload.response && action.payload.response.status) {
        toast.error(action.payload.response.statusText, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return { ...state, officer: state.officer, fetching: false };
      } else {
        return { ...state, fetching: false };
      }
    default:
      return { ...state };
  }
}
