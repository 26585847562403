import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { logoutAction } from 'actions/logoutAction';

function Logout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(logoutAction());
    navigate('/login');
  }, [dispatch, navigate]);

  return null;
}

export default Logout;
