import { toast } from 'react-toastify';

import { GET_OFFICER_REVIEW, SAVE_REVIEW_REPLY, UN_PUBLISH_REVIEW } from 'actions/action_types';

const initialValues = { fetching: true, reviewsData: {}, login: false, updated: false, unpublished_review: null };

export default function ReviewReducer(state = initialValues, action) {
  switch (action.type) {
    case GET_OFFICER_REVIEW:
      if (
        (action.payload.response && action.payload.response.status === 200) ||
        (action.payload.status && action.payload.status === 200)
      ) {
        return { ...state, fetching: false, reviewsData: action.payload.data };
      } else if (
        (action.payload.response && action.payload.response.status === 401) ||
        (action.payload.status && action.payload.status === 401)
      )
        return { ...state, fetching: false, reviewsData: {}, login: true };
      else if (
        (action.payload.response && action.payload.response.status === 403) ||
        (action.payload.status && action.payload.status === 403)
      )
        return { ...state, fetching: false, reviewsData: {}, login: true };
      else {
        return { ...state, fetching: false };
      }
    case SAVE_REVIEW_REPLY:
      if (
        (action.payload.response && action.payload.response.status === 200) ||
        (action.payload.status && action.payload.status === 200)
      ) {
        toast.success('Successfully updated reply.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        const reviewId = action.payload.data.review;
        state.reviewsData.reviews = state.reviewsData.reviews.filter(data => {
          if (data.review.id === reviewId) {
            data.comment = action.payload.data;
          }
          return data;
        });

        return {
          ...state,
          fetching: false,
          reviewsData: state.reviewsData,
          updated: true,
          comment: action.payload.data,
        };
      } else if (
        (action.payload.response && action.payload.response.status === 201) ||
        (action.payload.status && action.payload.status === 201)
      ) {
        toast.success('Successfully replied.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        const reviewId = action.payload.data.review;
        state.reviewsData.reviews = state.reviewsData.reviews.filter(data => {
          if (data.review.id === reviewId) {
            data.comment = action.payload.data;
          }
          return data;
        });

        return {
          ...state,
          fetching: false,
          reviewsData: state.reviewsData,
          updated: true,
          comment: action.payload.data,
        };
      } else if (
        (action.payload.response && action.payload.response.status === 401) ||
        (action.payload.status && action.payload.status === 401)
      ) {
        toast.error('You are not authenticated', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return { ...state, reviewsData: {}, login: true, updated: false };
      } else {
        return state;
      }
    case UN_PUBLISH_REVIEW:
      if (
        (action.payload.response && action.payload.response.status === 200) ||
        (action.payload.status && action.payload.status === 200)
      ) {
        toast.success('Successfully Unpublished.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        const reviewId = action.payload.data.review_id;
        state.reviewsData.reviews = state.reviewsData.reviews.filter(data => {
          if (data.review.id === reviewId) {
            data.unpublished = true;
          }
          return data;
        });
        return {
          ...state,
          fetching: false,
          reviewsData: state.reviewsData,
          updated: true,
          unpublished_review: reviewId,
        };
      } else if (
        (action.payload.response && action.payload.response.status === 401) ||
        (action.payload.status && action.payload.status === 401)
      ) {
        toast.error('You are not authenticated', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return { ...state, reviewsData: {}, login: true, updated: false };
      } else if (
        (action.payload.response && action.payload.response.status === 417) ||
        (action.payload.status && action.payload.status === 417)
      ) {
        const errorMessage = action.payload.response
          ? action.payload.response.data.error
          : action.payload.data && action.payload.data.error
          ? action.payload.data.error
          : 'Something went wrong.';
        toast.error(errorMessage, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return { ...state };
      } else {
        return state;
      }
    default:
      return state;
  }
}
